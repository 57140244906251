export const problemStatements = {
  Healthcare: [
    {
      title: "Smart Organ Donation Network",
      intro:
        "Organ donation saves countless lives, yet inefficiencies in the current system often result in missed opportunities. A streamlined and transparent process is crucial to ensure timely and equitable allocation of organs to recipients.",
      description:
        "## Introduction\nOrgan donation saves countless lives, yet inefficiencies in the current system often result in missed opportunities. A streamlined and transparent process is crucial to ensure timely and equitable allocation of organs to recipients.\n\n## Problem Statement\nThe current organ donation process is riddled with challenges, including delays in matching donors with recipients, lack of transparency, and inefficient prioritization of cases. Participants are encouraged to craft an innovative solution that addresses these challenges by improving the efficiency, transparency, and reliability of the organ donation system. The solution should enable effective prioritization of recipients based on factors such as medical urgency, compatibility, and geographical proximity.\n\n## Impact\nSolving this problem will increase the availability of life-saving organs, reduce transplant delays, and instill trust and transparency in the organ donation process, ultimately saving countless lives.",
    },
    {
      title: "Secure and Transparent Clinical Data Sharing for Rare Diseases",
      intro:
        "Rare diseases affect millions globally, but research progress is hindered by the scarcity of patient data and limited collaboration between research institutions. A secure and transparent mechanism for data sharing is essential to advance treatments.",
      description:
        "## Introduction\nRare diseases affect millions globally, but research progress is hindered by the scarcity of patient data and limited collaboration between research institutions. A secure and transparent mechanism for data sharing is essential to advance treatments.\n\n## Problem Statement\nClinical trials for rare diseases face major obstacles due to limited patient populations and minimal collaboration among research institutions. The lack of data sharing between centers results in inefficient trials and delays in developing personalized treatments. This challenge hinders progress in rare disease research, making it essential to create a solution that enables secure, transparent, and effective data exchange. The aim is to improve trial outcomes and speed up the discovery of new treatments for rare diseases.\n\n## Impact\nSolving this problem will advance global collaboration, improve treatment options for rare diseases, and provide hope to millions of patients and their families.",
    },
    {
      title: "Optimized Emergency Room Resource Allocation",
      intro:
        "Emergency rooms often face overcrowding, leading to inefficient use of resources and longer wait times. Hospitals need tools to predict patient inflow and optimize resource allocation to ensure better outcomes.",
      description:
        "## Introduction\nEmergency rooms often face overcrowding, leading to inefficient use of resources and longer wait times. Hospitals need tools to predict patient inflow and optimize resource allocation to ensure better outcomes.\n\n## Problem Statement\nEmergency rooms experience unpredictable surges in patient volumes, resulting in overstressed medical staff, long wait times, and suboptimal patient care. The lack of foresight in managing these surges hampers the ability to allocate resources effectively. Participants are invited to design and implement a solution to predict patient inflows and help optimize resource management. This solution should enable hospitals to better prepare for and manage patient demand, ensuring critical cases receive timely attention.\n\n## Impact\nSolving this problem will improve emergency room efficiency, reduce patient wait times, and save lives by ensuring critical resources are always available when needed.",
    },
  ],
  Finance: [
    {
      title: "Digital Marketplace for SME Trade Finance",
      intro:
        "Small and Medium Enterprises (SMEs) are key drivers of economic growth and global trade. However, accessing trade finance and credit products remains a challenge for many SMEs due to a lack of awareness about the available options and a fragmented marketplace.",
      description:
        "## Introduction\nSmall and Medium Enterprises (SMEs) are key drivers of economic growth and global trade. However, accessing trade finance and credit products remains a challenge for many SMEs due to a lack of awareness about the available options and a fragmented marketplace.\n\n## Problem Statement\nDespite the growing need for trade finance, there is no centralized platform that allows SMEs to easily discover and connect with trade financiers. Financial institutions such as banks, insurance companies, and marketplace lenders are often disconnected from SMEs, and the absence of a reliable system for cross-border trade finance adds complexity. Participants are invited to design a solution that bridges this gap, facilitating access to trade finance products while enhancing transparency and cross-border trade visibility.\n\n## Impact\nSolving this problem will empower SMEs with the tools to easily access trade finance, fostering global trade and economic growth by reducing barriers to credit and improving financial inclusivity.",
    },
    {
      title: "Automating Insider Trading Detection",
      intro:
        "Insider trading poses a serious threat to the integrity of financial markets, undermining investor confidence. Compliance departments face significant challenges in identifying and preventing insider trading activities, as the manual process of monitoring transactions is both time-consuming and prone to errors.",
      description:
        "## Introduction\nInsider trading poses a serious threat to the integrity of financial markets, undermining investor confidence. Compliance departments face significant challenges in identifying and preventing insider trading activities, as the manual process of monitoring transactions is both time-consuming and prone to errors.\n\n## Problem Statement\nCompliance teams are tasked with the overwhelming responsibility of manually verifying vast numbers of transactions to detect suspicious trading activity. Existing methods often fail to efficiently identify complex fraudulent patterns, leaving room for insider trading to go undetected. Participants are invited to create a solution that leverages advanced technologies like machine learning to improve the detection and prevention of insider trading, ultimately safeguarding the financial markets.\n\n## Impact\nSolving this problem will increase the efficiency of compliance teams, restore trust in financial markets, and protect investors by ensuring fairer market conditions free from insider trading.",
    },
    {
      title: "Personalized Tax Optimization for Maximum Savings",
      intro:
        "Tax season can be daunting for individuals and businesses, as understanding complex tax codes and finding eligible deductions requires significant time and expertise. Without personalized guidance, many taxpayers miss out on potential savings.",
      description:
        "## Introduction\nTax season can be daunting for individuals and businesses, as understanding complex tax codes and finding eligible deductions requires significant time and expertise. Without personalized guidance, many taxpayers miss out on potential savings.\n\n## Problem Statement\nThe process of tax optimization is often complex, with users struggling to identify which deductions or credits apply to their unique financial circumstances. Current solutions fail to provide personalized and actionable recommendations, leading to missed opportunities for maximizing tax savings. Participants are encouraged to design a solution that offers personalized tax insights, streamlining the tax filing process and enabling users to maximize savings.\n\n## Impact\nSolving this problem will simplify tax filing for individuals and businesses, providing them with tailored, actionable recommendations that optimize their tax savings and reduce the stress associated with the tax season.",
    },
  ],
};
